<script lang="ts" setup>
/**
 * * CommonButton component
 *
 * * Levels: Primary, Secondary, Tertiary ( default value is undefined -> Receiving primary from getStyle )
 *      Primary buttons are filled,
 *      Secondary buttons are outlined,
 *      Tertiary buttons are text with neutral(gray) colour by default
 *
 * * Colours: Orange, Gray, Green, Red, Blue ( default value is the purple colour with null value )
 *      Colours refer to severity orange-secondary, gray-neutral, green-success, red-danger and blue-info
 *
 * * Types: Create, Save, Delete, Back, Next, Cancel, Action( default value is empty )
 *      The types represent the usage of the button
 *      By defining type, also applies some default label, icon and aria-label to the buttons
 *      * type="create" by default gets level=primary
 *      * type="save" by default gets level=primary
 *      * type="cancel" by default gets level=secondary
 *      * type="back" by default gets level=tertiary
 *      * type="next" by default gets level=primary
 *      * type="action" by default gets level=tertiary
 *
 * * All the default values can be overwrited by giving the Primevue's Property-Value to the CommonButton component
 *
 * * CommonButton component when click or keydown.enter event occurs emits back the event that was occured and @pressed
 * * We can handle different click / keydown enter events or handle them the same way by listening to @pressed
 *
 * * CommonButton component has 2 available slots #icon and #default
 * */

import { match } from 'ts-pattern';

type ButtonLevel = 'primary' | 'secondary' | 'tertiary';
type ColourType = 'orange' | 'gray' | 'green' | 'red' | 'blue';
type ButtonType = 'create' | 'save' | 'delete' | 'back' | 'next' | 'cancel' | 'action';

const props = withDefaults(
    defineProps<{
        level?: ButtonLevel;
        type?: ButtonType;
        colour?: ColourType;
        hideIcon?: boolean;
        hideLabel?: boolean;
    }>(),
    { level: undefined, type: undefined, colour: undefined, hideIcon: false, hideLabel: false },
);

const startingStyle = {
    type: 'button',
    size: 'small',
};

const getStyle = (buttonType: ButtonLevel | undefined) => {
    return match<ButtonLevel | undefined>(buttonType)
        .with('primary', () => ({
            ...startingStyle,
        }))
        .with('secondary', () => ({
            ...startingStyle,
            outlined: true,
        }))
        .with('tertiary', () => ({
            ...startingStyle,
            text: true,
            severity: 'neutral',
        }))
        .otherwise(() => ({
            ...startingStyle,
        }));
};
const getColour = computed(() => {
    return match<ColourType | undefined>(props?.colour)
        .with('orange', () => ({
            severity: 'secondary',
        }))
        .with('gray', () => ({
            severity: 'neutral',
        }))
        .with('green', () => ({
            severity: 'success',
        }))
        .with('red', () => ({
            severity: 'danger',
        }))
        .with('blue', () => ({
            severity: 'info',
        }))
        .otherwise(() => {});
});
const getType = computed(() => {
    return match<ButtonType | undefined>(props?.type)
        .with('create', () => ({
            label: props.hideLabel ? null : 'Create',
            icon: props.hideIcon ? null : 'pi pi-plus',
            ariaLabel: 'Create',
            ...getStyle(props.level ?? 'primary'),
        }))
        .with('save', () => ({
            label: props.hideLabel ? null : 'Save',
            icon: props.hideIcon ? null : 'pi pi-save',
            ariaLabel: 'Save',
            ...getStyle(props.level ?? 'primary'),
        }))
        .with('delete', () => ({
            label: props.hideLabel ? null : 'Delete',
            icon: props.hideIcon ? null : 'pi pi-trash',
            ariaLabel: 'Delete',
            ...getStyle(props.level ?? 'tertiary'),
        }))
        .with('back', () => ({
            label: props.hideLabel ? null : 'Back',
            icon: props.hideIcon ? null : 'pi pi-chevron-left',
            ariaLabel: 'Back',
            ...getStyle(props.level ?? 'tertiary'),
        }))
        .with('next', () => ({
            label: props.hideLabel ? null : 'Next',
            icon: props.hideIcon ? null : 'pi pi-chevron-right',
            ariaLabel: 'Next',
            ...getStyle(props.level ?? 'primary'),
        }))
        .with('cancel', () => ({
            label: props.hideLabel ? null : 'Cancel',
            icon: props.hideIcon ? null : 'pi pi-times',
            ariaLabel: 'Cancel',
            ...getStyle(props.level ?? 'secondary'),
        }))
        .with('action', () => ({
            label: null,
            ...getStyle(props.level ?? 'tertiary'),
        }))
        .otherwise(() => {});
});

const emitFn = (e: Event, emitTopic: 'click' | 'enter') => {
    emit('pressed', e);
    emit(emitTopic, e);
};

const emit = defineEmits(['pressed', 'click', 'enter']);
</script>

<template>
    <PButton
        v-bind="{ ...getType, ...getStyle(props?.level), ...getColour, ...$attrs }"
        class="px-3 py-2"
        @click="emitFn($event, 'click')"
        @keydown.enter="emitFn($event, 'enter')"
    >
        <template #icon>
            <slot name="icon"></slot>
        </template>
        <template #default>
            <slot />
        </template>
    </PButton>
</template>

<style></style>
